import './src/styles/index.scss';
import detectMobile from './src/utils/detectMobile';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
export { default as wrapRootElement } from './src/state/reduxWrapper';
export { wrapPageElement } from "./gatsby-shared";

export const onClientEntry = () => {
  window._wq = window._wq || [];
  detectMobile()
}

export const onRouteUpdate = () => {
  clearAllBodyScrollLocks()
}
