import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script src="//fast.wistia.com/assets/external/E-v1.js" async />
    </>
  )
}