// Defining the State
const initialState = {
  menuIsOpen: false,
  scrollReady: false,
  videoPlayerIsOpen: {
    open: false,
    id: null,
  }
};

// TYPES
const HANDLE_MENU_STATUS = "HANDLE_MENU_STATUS";
const SCROLL_READY = "SCROLL_READY";
const HANDLE_VIDEO_PLAYER_STATUS = "HANDLE_VIDEO_PLAYER_STATUS";

// ACTIONS
export const toggleMenuOpen = (status) => ({
  type: HANDLE_MENU_STATUS,
  payload: status
});
export const scrollReady = (isReady) => ({
  type: SCROLL_READY,
  payload: { scrollReady: isReady },
});
export const toggleVideoPlayer = (status) => ({
  type: HANDLE_VIDEO_PLAYER_STATUS,
  payload: status,
});

// SELECTORS
export const selectMenuIsOpen = (state) => state.utils.menuIsOpen;
export const selectScrollReady = (state) => state.utils.scrollReady;
export const selectVideoPlayer = (state) => state.utils.videoPlayerIsOpen;

// REDUCER
const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_MENU_STATUS:
      return {
        ...state,
        menuIsOpen: action.payload
      };
    case SCROLL_READY:
      return {
        ...state,
        scrollReady: action.payload.scrollReady,
      };
    case HANDLE_VIDEO_PLAYER_STATUS:
      return {
        ...state,
        videoPlayerIsOpen: {
          open: action.payload.open,
          id: action.payload.id,
        }
      };
    default:
      return state;
  }
};

export default utilsReducer;
