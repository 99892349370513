const detectMobile = () => {
  const userAgent =
    typeof window.navigator === "undefined" ? "" : navigator.userAgent

  const mobile = () => {
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return true
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return true
    }

    if (window.innerWidth < 1024) return true
    return false
  }

  if (window.isMobile === undefined) {
      window.isMobile = mobile()
      if (window.setIsMobile) window.setIsMobile()
  }
}

export default detectMobile